import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import dowDoc from "../../images/download-doc.png"
import felisd1detail from "../../images/Felis-D1.webp"
import felisd1Doc from '../../docs/Felis-D1.pdf'

class Felisd1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return <Layout>
      <SEO title="Felis-D1 | Tumble Dryer Controller"
      description="Proalcs's Felis-D1 an economic model with 2 programs designed for industrial drying machines" />
      <Container>
        <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
          <br></br>
          <br></br>
          <Link className='link-breadcrumb' to='/products'><h2 style={{ fontSize: '19px' }}>Products /</h2> </Link>
          <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Felis-D1 </h1>
        </div>
        <Row>

          <Col sm className='product-detail-head'>
            <div className={`product-detail-header-image´  ${this.state.isToggleOn ? 'product-detail-header-image' : 'product-detail-header-image-zoom'}`} >
              <img className='product-detail' onClick={this.handleClick} src={felisd1detail} alt="felis-d1-prolacs" />
            </div>
          </Col>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Felis-D1</h1>
            </div>
            <h2> Tumble Dryer Controller</h2>
            <p> It is an economic model with 2 programs designed for industrial drying machines, suitable for heavy industrial conditions. The simplified Custom Desing is an easy-to-use, standard serial controller thanks to its LCD display and membrane keypad. Resistant to vibration and dust. The design of the control panel is customized according to the needs and designed in accordance with the brand according to customer demands.</p>
          </Col>
        </Row>
        <Row>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Documents</h1>
            </div>
            <br></br>
            <Row>
              <Col className='product-detail-doc-name'>
                <a  target="_blank" rel="noopener noreferrer"   href={felisd1Doc} >  <img className='product-detail-doc' src={dowDoc} alt="prolacs felis d1" />
                  <h1>Felis-D1</h1>  </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1 >Features</h1>
        </div>
        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Inputs/Outputs</h1>
        </div>

        <Row className="product-table-row">
          <Col>
            <p>Analog Input</p>
          </Col>
          <Col>
            <p>Temperature Sensor Input (-10~100 °C)</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>

        <Row className="product-table-row">
          <Col>
            <p>Digital Input</p>
          </Col>
          <Col>
            <p>5-24V AC/DC </p>
          </Col>
          <Col className="lastp">
            <p>4</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Output</p>
          </Col>
          <Col>
            <p>
              {" "}
              24V AC/DC 1A Relay NO
          <br />
              (Optinonal 220V AC)
        </p>
          </Col>
          <Col className="lastp">
            <p>4</p>
          </Col>
        </Row>

        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Programming</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Program Count</p>
          </Col>
          <Col>
            <p>5 Default Programs</p>
          </Col>
          <Col className="lastp">
            <p>5 </p>
          </Col>
        </Row>
        <div>
          <h1 className='controllerheadtext' style={{width:"100%"}}>Technical Specifications</h1>
        </div>
        <Row className="product-table-row">
          <Col>
            <p>Operating Voltage</p>
          </Col>
          <Col>
            <p>12V AC/DC (Optional 220V AC) </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Power Consumption</p>
          </Col>
          <Col>
            <p>Max 4W</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Temperature</p>
          </Col>
          <Col>
            <p>0~60 °C</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Humidty</p>
          </Col>
          <Col>
            <p>%10 - 85 (Non-Condensing) </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Isolation </p>
          </Col>
          <Col>
            <p>2000V Digital </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Keyboard </p>
          </Col>
          <Col>
            <p>8 Keys </p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Display </p>
          </Col>
          <Col>
            <p>45x30 mm Custom Designed LCD</p>
          </Col>
          <Col className="lastp" />
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Processor</p>
          </Col>
          <Col>
            <p>Micro Processor </p>
          </Col>
          <Col className="lastp" />
        </Row>

      </Container >
    </Layout >
  }
};
export default Felisd1
